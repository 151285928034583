import { FormControl, FormLabel, Grid, Input, Box, Button,Alert , AlertIcon} from '@chakra-ui/react'
import API from 'helpers/api';
import {useState, useEffect} from 'react'

import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
import DatePicker from 'sassy-datepicker';
import DialogModal from 'components/DialogModal';

function AccountSettings() {

  const history = useHistory();
  const [fullName, setFullName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("");
  const [dob, setDob]= useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [cookies, setCookie] = useCookies(['access_token']);

  const [isModalOpen, setModelOpen] = useState(false);
  const [isLoading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState("");

  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
  
  
  useEffect(async ()=>{

    const token = cookies.access_token
    if(!token){
      history.push("/login")
      return;
    }

    const response = await fetch(API.USER, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${token}`
      }
    });
    const content = await response.json();
    console.log("content ", content)
    if(content.status==='Success'){

      setEmail(content.data.user.email)
      setAddress(content.data.profile?.address)
      setDob(content.data?.profile?.dob)
      setFullName(content.data?.profile?.full_name)
      setPhone(content.data?.profile?.mobile)
    }
  },[])

  
  const OnUpateButtonPressed=async()=>{
    const token = cookies.access_token
    if(!token){
      history.push("/login")
      return;
    }

    setModelOpen(true)
    setLoading(true)
    const response = await fetch(API.POST_PROFILE, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${token}`
      },
      body: JSON.stringify({full_name: fullName, mobile: phone, address: address, dob:"2020-10-10"})
    });
    const content = await response.json();
 
    setLoading(false)
    if(content.status==="Success"){
      setModelOpen(false)
      setShowUpdateSuccess(true)
    }else{
      setErrorMsg(content.message)
    }
  }

  return (
    <>
     { showUpdateSuccess && <Alert status='success'  mb={5}> <AlertIcon />Profile Update Successful.</Alert> }
    <Grid
      templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
      gap={6}
    >
       
      <FormControl id="fullName">
        <FormLabel>Full Name</FormLabel>
        <Input 
          focusBorderColor="brand.blue" 
          type="text" 
          placeholder="Full Name" 
          value={fullName}
          onChange={(e)=>setFullName(e.target.value)}
        />
      </FormControl>
      <FormControl id="emailAddress">
        <FormLabel>Email Address</FormLabel>
        <Input
          focusBorderColor="brand.blue"
          type="email"
          value={email}
          placeholder="xyz@gmail.com"
        />
      </FormControl>

      <FormControl id="phoneNumber">
        <FormLabel>Phone Number</FormLabel>
        <Input
          focusBorderColor="brand.blue"
          type="tel"
          value={phone}
          placeholder="(408) 996–1010"
          onChange={(e)=>setPhone(e.target.value)}
        />
      </FormControl>

      <FormControl id="address">
        <FormLabel>Address</FormLabel>
        <Input
          focusBorderColor="brand.blue"
          type="text"
          value={address}
          placeholder={"lmcity ward 3"}
          onChange={(e)=>setAddress(e.target.value)}
        />
      </FormControl>
      
      {/* <FormControl >
        <FormLabel>Date of Birth</FormLabel>
        <Input
          focusBorderColor="brand.blue"
          type="text"
          value={address}
         
        />
        <DatePicker onChange={()=>console.log("-----")} />
      </FormControl> */}
    </Grid>

    <Box mt={5} py={5} px={0} borderTopWidth={1} borderColor="brand.light">
      <Button onClick={()=>OnUpateButtonPressed()}>Update</Button>
    </Box>

    <DialogModal isOpen={isModalOpen} isLoading={isLoading} onClose={()=>setModelOpen(false)} desc={errorMsg}/>
    </>
  )
}

export default AccountSettings
