import {
  FormControl,
  FormLabel,
  Grid,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Box
} from '@chakra-ui/react'
import MyDropzone from 'components/MyDropzone';
import API from 'helpers/api';
import React, {useEffect, useState} from 'react'
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";

import {useDropzone} from 'react-dropzone';
import Nepali from 'helpers/np';

function DocumentSettings() {
  const history = useHistory();
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  const [cookies, setCookie] = useCookies(['access_token']);
  const [documents, setDocuments] = useState([]);

  useEffect(async()=>{
    const token = cookies.access_token
    if(!token){
      history.push("/login")
      return;
    }

    const response = await fetch(API.GET_DOCUMENTS, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${token}`
      }
    });
    const content = await response.json();
   
    if(content.status==='Success'){
      setDocuments(content.data)
    }
  },[])

  const OnImageDeleteClick=async(image)=>{

    const token = cookies.access_token
    if(!token){
      history.push("/login")
      return;
    }

    const response = await fetch(API.DELETE_DOCUMENT+"/"+image.id, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${token}`
      }
    });
    const content = await response.json();
    if(content.status==="Success"){
      const filteredImages = documents.filter(doc => doc.id !== image.id)
      setDocuments(filteredImages);
    }
   
   
    // if(content.status==='Success'){
    //   setDocuments(content.data)
    // }

  }

  return (
    <Grid
      templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
      gap={6}
    >
      <FormControl id="citizenship">
        <FormLabel>नागरिकता</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'citizenship'} imageUrl={ documents?.filter(item=>item.type==="citizenship")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="citizenship">
        <FormLabel>{Nepali.billbook}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'billbook'} imageUrl={ documents?.filter(item=>item.type==="billbook")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="citizenship">
        <FormLabel>{Nepali.business}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'business'} imageUrl={ documents?.filter(item=>item.type==="business")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="citizenship">
        <FormLabel>{Nepali.covid}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'covid'} imageUrl={ documents?.filter(item=>item.type==="covid")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="citizenship">
        <FormLabel>{Nepali.election}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'election'} imageUrl={ documents?.filter(item=>item.type==="election")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="citizenship">
        <FormLabel>{Nepali.electricity}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'electricity'} imageUrl={ documents?.filter(item=>item.type==="electricity")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="citizenship">
        <FormLabel>{Nepali.insurance}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'insurance'} imageUrl={ documents?.filter(item=>item.type==="insurance")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="citizenship">
        <FormLabel>{Nepali.land}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'land'} imageUrl={ documents?.filter(item=>item.type==="land")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="citizenship">
        <FormLabel>{Nepali.liscense}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'liscense'} imageUrl={ documents?.filter(item=>item.type==="liscense")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="national">
        <FormLabel>{Nepali.national}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'national'} imageUrl={ documents?.filter(item=>item.type==="national")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="national">
        <FormLabel>{Nepali.pan}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'pan'} imageUrl={ documents?.filter(item=>item.type==="pan")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="national">
        <FormLabel>{Nepali.passport}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'passport'} imageUrl={ documents?.filter(item=>item.type==="passport")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="national">
        <FormLabel>{Nepali.property}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'property'} imageUrl={ documents?.filter(item=>item.type==="property")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="national">
        <FormLabel>{Nepali.tap}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'tap'} imageUrl={ documents?.filter(item=>item.type==="tap")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="national">
        <FormLabel>{Nepali.marriage}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'marriage'} imageUrl={ documents?.filter(item=>item.type==="marriage")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="national">
        <FormLabel>{Nepali.naksa}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'naksa'} imageUrl={ documents?.filter(item=>item.type==="naksa")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="national">
        <FormLabel>{Nepali.certificate}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'certificate'} imageUrl={ documents?.filter(item=>item.type==="certificate")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      <FormControl id="national">
        <FormLabel>{Nepali.others}</FormLabel>
        <Box border={'dashed'} p={5} bg={'rgb(223 218 218)'}>
          <MyDropzone type={'others'} imageUrl={ documents?.filter(item=>item.type==="others")} onUploadSuccess={(type, content)=>setDocuments(oldArray => [...oldArray, ...content])} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
        </Box>
      </FormControl>

      
    </Grid>
  )
}

export default DocumentSettings