
import images from './images'
const items = [
  {
    id: "1",
    name: "घर  जग्गा  नामसारी सिफारिस",
    description:
      ["१) निवेदकको नागरिकता प्रमाणपत्रका प्रतिलिपि", 
      "२) जग्गा धनी प्रमाण पुर्जाको प्रतिलिपि",
       "३) चालु आ. व. सम्मको घर भए सम्पत्ति कर तिरेको प्रमाणपत्र, जग्गा भए मालपोत तिरेको रसिद ",
       "४) नाता प्रमाणित प्रमाणपत्रका प्रतिलिपि", 
       "५) सर्जमिन मुचल्का गरी बुझनु पर्ने भएमा साक्षी बस्नेको नागरिकता प्रमाणपत्रको प्रतिलिपि",
       "६) दुई प्रति फाटो"],
    code: "#1abc9c",
    icon: images.settlement,
    url: "https://lmcityward3.com/storage/reports/pgPllX8W6wufO8BBdDyS53bAnKVX0v37OoPdjf5V.pdf"
  },
  {
    id: "2",
    name: "मोही लगत कट्टा सिफारिस",
    description:
      [
        "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
        "२) जग्गा धनी प्रमाण पुर्जाकोप्रतिलिपि",
        "३) जग्गाका प्रमाणित नापी नक्सा",
        "४) नाता प्रमाणित प्रमाणपत्रका प्रतिलिपि",
        "५) जग्गाका श्रेस्ता र फिल्डबुकका प्रमाणित प्रतिलिपि",
        "६) सर्जमिन मुचल्का गरी बुझनु पर्ने भएमा साक्षी बस्नेकोनागरिकता प्रमाणपत्रकोप्रतिलिपि",
       " ७) चालुआ. व. सम्मकोघर भए सम्पत्ति कर तिरेको प्रमाणपत्र, जग्गा भए मालपोत तिरेको रसिद"
      ],
    code: "#1abc9c",
    icon: images.smart_farm,
    url: "https://lmcityward3.com/storage/reports/RD4368QAzwMj0qdcyrdscIo56YizSMvXpmvQnnzA.pdf"
  },
  {
    id: "3",
    name: "घर कायम सिफारिस",
    description:
      [
        "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
        "२) जग्गा धनी प्रमाण पुर्जाकोप्रतिलिपि",
        "३) नक्सा पास प्रमाणपत्रको प्रतिलिपि",
        "४) निर्माण सम्पन्न प्रमाणपत्रको प्रतिलिपि",
        "५) जग्गाका श्रेस्ता र फिल्डबुकका प्रमाणित प्रतिलिपि",
        "६) चालुआ. व. कोसम्पत्ति कर तिरेको प्रमाणपत्र "
      ],
    code: "#1abc9c",
    icon: images.house,
    url:"https://lmcityward3.com/storage/reports/qU3Pz3vJ3qnH1k4lCXWqi9JxMfAG1hq8fDQ1nj1D.pdf"
  },
  {
    id: "4",
    name: "घर बाटो प्रमाणित",
    description:
      ["१) निवेदन (बाटोको नाम, टोल समेत खुलाउने) नागरिकता प्रमाणपत्रको प्रतिलिपि",
       "२) जग्गाधनी प्रमाण पुर्जाको प्रमाणित प्रतिलिपि",
        "३) जग्गा रहेको क्षेत्रको प्रमाणित सक्कलनापी नक्सा ", 
        "४) चालु आर्थिक वर्ष सम्मको मालपोत र घर जग्गा कर वा एकीकृत सम्पत्ति कर तिरेको रसिद वा कर निर्धारण स्वीकृत भएको कागजात", "५) लिने दिने दुवै व्यक्ति नागरिकताका प्रमाणपत्रको प्रमाणपत्र प्रमाणित प्रतिलिपी सहित उपस्थित हुनु पर्ने वा निजहरुले दिएको अधिकृत वारेसको प्रमाणित प्रतिलिपी", "६) स्थलगत निरीक्षण प्रतिवेदन"],
    code: "#1abc9c",
    icon: images.road
  },
  {
    id: "5",
    name: "छात्रबृत्ति सिफारिस",
    description:
      [
        "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
       " २) विद्यार्थीको जन्म दर्ता",
        "३) स्कुलमा अध्ययनको परिचयपत्र",
        "४) वडा बाहिरको भए विपन्न÷शहरी गरीव÷आर्थिक अवस्था कमजोरको सिफारिसपत्र",
        "५) घरधनीले सम्पत्ति कर तिरेको कागजपत्र÷वहालमा भए बहाल कर तिरेको कागजपत्र",
        "६) भाडामा बस्नेको हकमा सम्वन्धित गा.पा.÷न.पा.बाट सिफारिस ल्याउनु पर्ने "
      ],
    code: "#1abc9c",
    icon: images.scholarship,
    url:"https://lmcityward3.com/storage/reports/cPr5JjlGikI5h73kTnNokeFmRvN8iI3lak6CrBot.pdf"
  },
  {
    id: "6",
    name: "आदिवासी जनजाति प्रमाणित",
    description:
      [
        "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
        "२) जनजाति कार्डकोप्रतिलिपि",
       "३) चालुआ. व. कोसम्पत्ति कर तिरेको प्रमाणपत्र"      ],
    code: "#1abc9c",
    icon: images.wheelchair,
    url:"https://lmcityward3.com/storage/reports/qm3PeA12f3yldcwqbp6ynY2bWqYA8hb7fT1IQqCm.pdf"
  },
  {
    id: "7",
    name: "अस्थाई बसोबास सिफारिस",
    description:
      [
        "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
        "२) घर धनीको नागरिकता",
        "३) चालुआ. व. कोसम्पत्ति कर तिरेको प्रमाणपत्र ",
        "४) विदेशी भए नेपाल प्रवेश गरेको पासपोर्टको प्रतिलिपि",
        "५) कम्तिमा तीन महिना बसोबास गरेको सम्झौता पत्र"
      ],
    code: "#1abc9c",
    icon: images.refugee,
    url:"https://lmcityward3.com/storage/reports/XQMpQ41GAM4ZsjhSYvYiYtEzUKxIE1n9B4S0I4OV.pdf"
  },
  {
    id: "8",
    name: "स्थायी बसोबास सिफारिस",
    description:
      [
        "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
       " २) बसाईसराइ भए बसाइसराइ प्रमाणपत्रको प्रतिलिपि",
       " ३) चालुआ. व. कोसम्पत्ति कर तिरेको प्रमाणपत्र "
      ],
    code: "#1abc9c",
    icon: images.settlement,
    url:"https://lmcityward3.com/storage/reports/caNzfukzuiEeCgXDas05IfeFhilrtFkuj72x4rZ3.pdf"
  },
  {
    id: "9",
    name: "आर्थिक अवस्था कमजोर वा विपन्नता प्रमाणित",
    description:
      [
       " १) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
       " २) बसाईसराइ भए बसाइसराइ प्रमाणपत्रको प्रतिलिपि",
        "३) विषय प्रमाणित गर्ने कागजात भए सोको प्रतिलिपि",
        "४) चालुआ. व. कोसम्पत्ति कर तिरेको प्रमाणपत्र "
      ],
    code: "#1abc9c",
    icon: images.money_1,
    url:"https://lmcityward3.com/storage/reports/lyoq7SPLuk1JZXP3JZhMymMSwUEvivDljVrF9oqS.pdf"
  },
  {
    id: "10",
    name: "विद्युत जडान सिफारिस",
    description:
      [
        "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
        "२) जग्गा धनी प्रमाणपूर्जाको प्रतिलिपि",
        "३) नक्सा पास प्रमाणपत्रको प्रतिलिपि",
        "४) चालुआ. व. कोसम्पत्ति कर तिरेको प्रमाणपत्र "
      ],
    code: "#1abc9c",
    icon: images.light_bulb,
    url:"https://lmcityward3.com/storage/reports/8gCoAZ9DIgcxoKwLvMBn1Ph3IAe0U4TvFR7eTTQS.pdf"
  },

  {
    id: "11",
    name: "धारा जडान सिफारिस",
    description:
      [
        "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
        "२) जग्गा धनी प्रमाणपूर्जाको प्रतिलिपि",
        "३) नक्सा पास प्रमाणपत्रको प्रतिलिपि",
        "४) चालुआ. व. कोसम्पत्ति कर तिरेको प्रमाणपत्र "
      ],
    code: "#1abc9c",
    icon: images.clean_water,
    url: "https://lmcityward3.com/storage/reports/yWT4FJDOEDyT5zBc4FdY4Dm4QI7vxkhaf6iKRQxb.pdf"
  },
  {
    id: "12",
    name: "एक्कै हो भन्ने सिफारिस",
    description:
      [
        "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
        "२) के के मा फरक परेको हो सोको कागजात",
        "३) बसाइसराइ भए बसाइसराइको प्रतिलिपि",
        "४) चालुआ. व. कोसम्पत्ति कर तिरेको प्रमाणपत्र "
      ],
    code: "#1abc9c",
    icon: images.clean_water,
    url: "https://lmcityward3.com/storage/reports/xXiHUKKyeH2PIPr29F3YT8NxVAcY2hQriRQRscgz.pdf"
  },
  {
    id: "13",
    name: "ब्यवसाय बन्द सिफारिस",
    description:
     [ 
       "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
       "२) सक्कल व्यवसाय प्रमाणपत्र"
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/6NaZVQ0MFNM1f6MErXeas1EGD78tCFhiGCxnM5M8.pdf"
  },
  {
    id: "14",
    name: "ब्यवसाय ठाउँसारी सिफारिस",
    description:
     [ 
      "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
      "२) सक्कल व्यवसाय प्रमाणपत्र",
      "३) ठाउँ सारी जाने धरधनीको सम्झौतापत्रको प्रतिलिपि"
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/b1SeJLCZBwEAwvsMNGrFaLQxdSyyvjt2LPC6zGpG.pdf"
  },
  {
    id: "15",
    name: " कोर्ट फि मिनाहा सिफारिस",
    description:
     [ 
      "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
      "२) अदालतको पर्चा",
      "३) विपन्न÷एक्कल महिला÷असहाय व्यक्तिको कागजात"
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/OYkH6Tio0Bsfg4luqLlToKOVwy6XDawp6vwxVg32.pdf"
  },
  {
    id: "16",
    name: "नाबालक सिफारिस",
    description:
     [ 
      "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
      "२) श्रीमतीको नागरिकताको प्रतिलिपि",
      "३) विवाह दर्ताको प्रतिलिपि",
      "४) जन्म दर्ताको प्रतिलिपि",
      "५) वसाइसराइ भए बसाइसराइको प्रतिलिपि",
      "६) चालुआ. व. कोसम्पत्ति कर तिरेको प्रमाणपत्र"
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/TMq9gEl3TKBcuuOabLmRn88nMMWcTSonPUycBLXP.pdf"
  },
  {
    id: "17",
    name: "चौपाया सिफारिस ",
    description:
     [ 
      "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
      "२) चौपाय लाने नगरपालिका/गा.पा.को सिफारिस",
      "३) व्यवसाय दर्ता प्रमाणपत्रको प्रतिलिपि"
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/o5hXA0p0jTnJMveKyWt6zStWZMZg60LNThTgMZWh.pdf"
  },
  {
    id: "18",
    name: "संस्था दर्ता",
    description:
     [ 
      "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
      "२) घरधनीसंगको बहाल सम्झौता",
      "३) सम्पत्ति कर बुझाएको प्रमाणपत्र",
      "४) घरेलु÷बाणिज्य÷उद्योग÷कम्पनीमा दर्ता भए सोको कागज",
      "५) घर धनीको सम्पत्ति कर तिरेको प्रमाणपत्र"
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/zhfJvz3rRHftlhKfPhgmjpyFvoZJOaw7fnc8GfXA.pdf"
  },
  {
    id: "19",
    name: "विद्यालय ठाउँसारी सिफारिस",
    description:
     [ 
      "१) निवेदककोनागरिकता प्रमाणपत्रको प्रतिलिपि",
      "२) सक्कल व्यवसाय प्रमाणपत्र",
      "३) ठाउँ सारी जाने धरधनीको सम्झौतापत्रको प्रतिलिपि "
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/GVpfJOdiUs6KRmxKmDWvwUOl5kW2kr75s6Qo9IDa.pdf"
  },
  {
    id: "20",
    name: "विद्यालय संचालन/कक्षाको सिफारिस ",
    description:
     [ 
      "१) लेटर हेडमा लेखेको निवेदन",
      "२) व्यवसाय प्रमाणपत्रको प्रतिलिपि",
      "३) स्थलगत सर्जमिन"
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/4rumGiOuzt1PB1L57Et2ChTu8yzBewqHF7vjUV0W.pdf"
  },

  {
    id: "21",
    name: "जग्गा दर्ता सिफारिस",
    description:
     [ 
      "१) निवेदकको नागरिकताको प्रतिलिपि",
      "२) उत्तार",
      "३) स्थलगत सर्जमिन "
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/jh1wksj3EF0dgWOz6J1ow2gAhjRwzIl5dOYUOwrx.pdf"
  },

  {
    id: "22",
    name: "संरक्षक सिफारिस",
    description:
     [ 
      "१) निवेदकको नागरिकताको प्रतिलिपि",
     " २) ७० वर्ष बृद्धको नागरिकताको प्रतिलिपि",
      "३) नाता खोलेको प्रमाणपत्र",
      "४) सम्पत्ति कर तिरेको प्रमाणपत्रको प्रतिलिपि"
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/yQN75KredxADQCubDiVJiVUK6a7bCkB0Kha7KniJ.pdf"
  },
  {
    id: "23",
    name: "बाटो कायम सिफारिस",
    description:
     [ 
      "१) निवेदकको नागरिकताको प्रतिलिपि",
      "२) जग्गाधनी प्रमाणपूर्जाको प्रतिलिपि",
      "३) घरभए सम्पत्ति कर तिरेको प्रमाणपत्र÷जग्गा भए मालपोत रसिद "
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/o9G1NOFa4pa0q9Mf08gKelhFj0SxhxUJjAV8OUuo.pdf"
  },
  {
    id: "24",
    name: "जिवित नाता प्रमाणित",
    description:
     [ 
      "१) निवेदकको नागरिकताको प्रतिलिपि",
      "२) नाता कायम गर्नु पर्ने सबैको नागरिकताको प्रतिलिपि",
      "३) दुई दुई प्रति अटो साईजको फोटो",
      "४) चालु आ.व.को सम्पत्ति कर बुझाएको प्रमाणपत्रको प्रतिलिपि "
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/QWqcLwR2Fhvors5zEJXqE0tSDbij9Z11u9yPOcUv.pdf"
  },

  {
    id: "25",
    name: "मृत्यु नाता प्रमाणित ",
    description:
     [ 
     " १) निवेदकको नागरिकताको प्रतिलिपि",
      "२) मृत्यु दर्ता प्रमाणपत्रको प्रतिलिपि",
      "३) तीन तीन प्रति अटो साईजको फोटो",
      "४) चालु आ.व.को सम्पत्ति कर बुझाएको प्रमाणपत्रको प्रतिलिपि"
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/MbwqViXIs7HuoM7kaK5dbVilcZQe0trum1Lfh4WV.pdf"
  },
  {
    id: "26",
    name: "निःशुल्क स्वास्थ्य उपचारको लागि सिफारिस",
    description:
     [ 
      "१) निवेदकको नागरिकताको प्रतिलिपि",
      "२) बिरामीको नागरिकता",
      "३) बिरामीको अस्पताल भर्नाको कागज"
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/T7J1IDRw1KUJYTlqsgaaiTquJOBUu3zRP5zbE1sh.pdf"
  },
  {
    id: "27",
    name: "संस्था दर्ता सिफारिस",
    description:
     [ 
     " १) संस्थाको लेटर हेडमा निवेदन",
      "२) सम्वन्धित ठाउँमा दर्ता परेको पत्र",
      "३) घरधनीसंगको सम्झौता पत्र",
      "४) संस्थाको विधान"
      ],
    code: "#1abc9c",
    icon: images.factory,
    url:"#"
  },
  {
    id: "28",
    name: "घर बाटो प्रमाणित",
    description:
     [ 
      "१) लिने दिनेको नागरिकताको प्रतिलिपि",
      "२) जग्गाधनी प्रमाणपूर्जाको प्रतिलिपि",
      "३) घरभए सम्पत्ति कर बुझाएको प्रमाणपत्र, जग्गाभए मालपोत रसिद",
      "४) बढीमा ६ महिनाभित्र निकालेको नापी नक्सा"      
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/GLuXUWtZSrKqMkTd560MLivotKGhY5w27B0I94gZ.pdf"
  },
  {
    id: "29",
    name: "चारकिल्ला प्रमाणित",
    description:
     [ 
      "१) निवेदकको नागरिकताको प्रतिलिपि",
      "२) जग्गाधनी प्रमाणपूर्जाको प्रतिलिपि",
      "३) घरभए सम्पत्ति कर बुझाएको प्रमाणपत्र, जग्गाभए मालपोत रसिद",
      "४) बढीमा ६ महिनाभित्र निकालेको नापी नक्सा "
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/DgZeOgvE5O7sidTw2i7F6KnID7czjPoVcRfTgyAe.pdf"
  },

  {
    id: "30",
    name: "जन्म मिति प्रमाणित ",
    description:
     [ 
      "१) निवेदकको नागरिकताको प्रतिलिपि",
      "२) वसाईसराई भए बसाईसराईको प्रमाणपत्रको प्रतिलिपि",
      "३) घरभए सम्पत्ति कर बुझाएको प्रमाणपत्र "
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/jqE8mzqZVKhJUWvPm1aiA47Yz791uO7k8TTvDNlP.pdf"
  },

  {
    id: "31",
    name: " बिवाह प्रमाणित ",
    description:
     [ 
     " १) दुलहा दुलहीको नागरिकता प्रमाणपत्रकोप्रतिलिपि",
     " २) बसाँई सरी आएकाको हकमा बसाइसराइ प्रमाण पत्र",
      "३) दुलाहा दुलही दुबैउपस्थित भई सनाखत गर्नुपर्ने।",
      "४) चालुआ. व. सम्मकोसम्पत्ति कर तिरेको प्रमाणपत्रको प्रतिलिपि",
      "५) वि।स।२०३६ पछिकोहकमा बिबाह दर्ता प्रमाण पत्रको प्रतिलिपि"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/TDorNkLPEiZk38gWGsEMpUxJnhSKr2kG1RWRrHm3.pdf"
  },

  {
    id: "32",
    name: "घर पाताल प्रमाणित",
    description:
     [ 
     " १) निवेदकको नागरिकताको प्रतिलिपि",
      "२) जग्गाधनी प्रमाणपूर्जाको प्रतिलिपि",
      "३) सम्पत्ति कर बुझाएको प्रमाणपत्रको प्रतिलिपि",
     " ४) स्थलगत निरीक्षण प्रतिवेदन"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/PcZvWivEnQXfzTfLInLUuJeR0LMVqrlEZ9MiqCIG.pdf"
  },

  {
    id: "33",
    name: "हकदार प्रमाणित",
    description:
     [ 
      "१) नागरिकता प्रमाणपत्रको प्रतिलिपि",
      "२) नाता प्रमाणित प्रमाण पत्रको प्रतिलिपि",
      "३) हकदार प्रमाणितका लागि स्थलगत सर्जमिन",
     " ४) हकदार प्रमाणित गर्नेथप प्रमाण कागज",
      "५) सम्पत्ति कर बुझाएको प्रमाणपत्रको प्रतिलिपि"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/aYpriRnSmKXPfCRD2tZtZYcs1clesaDDoqlIll8q.pdf"
  },
  {
    id: "34",
    name: "अबिवाहित प्रमाणित",
    description:
     [ 
      "१) नागरिकता प्रमाणपत्रको प्रतिलिपि",
      "२) सम्पत्ति कर बुझाएको प्रमाणपत्रको प्रतिलिपि",
      "३) स्थलगत सर्जमिन मुचुल्का",
      "४) सम्पत्ति कर बुझाएको प्रमाणपत्रको प्रतिलिपि"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/W8chbSLxFSFJvRJ81m3DM5cG1DzKf04VcPySyICp.pdf"
  },
  {
    id: "35",
    name: "जग्गाधनी प्रमाण पूर्जा हराएको सिफारिस ",
    description:
     [ 
      "१) निवेदकको नागरिकता प्रमाणपत्रको प्रतिलिपि",
      "२) जग्गा धनी प्रमाण पुर्जाको प्रतिलिपि",
      "३) घरभए सम्पत्ति कर बुझाएको प्रमाणपत्रको प्रतिलिपि",
      "३) जग्गाभए मालपोत तिरेको रसिद",
      "४) निवेदकको २ प्रति फोटा"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/vCygE6Esi8T9saYecNkUmcjF1VAuspQGVnM3hpye.pdf"
  },
  {
    id: "36",
    name: "ब्यवसाय दर्ता  ",
    description:
     [ 
      " १) निवेदन पत्र",
      "२) नागरिकता प्रमाणिपत्रको प्रमाणित प्रतिलिपी",
      "३) विदेशीको हकमा राहदानीको प्रमाणित प्रतिलिपि वा सम्बन्धित दुतावासको निजको परिचय खुल्ने सिफारिस",
      "४) २ प्रतिफोटो",
      "५) घर बहाल सम्झौता",
      "६) आफ्नै घर टहरा भए चा.लु. आ.व.सम्मको मालपोत र घरजग्गा कर तिरेको",
      "७) स्थानीय तहको नाममा दर्ता नगरी प्यान वा अन्य निकायमा दर्ता गरी व्यवसाय दर्ता गरेको हकमा"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/z6cn04C3LLtCLIJ5EvZUWPspeZ7OA8JbRAQess6H.pdf"
  },

  {
    id: "37",
    name: "मोही नामसारीको लागि सिफारिस",
    description:
     [ 
      "१) निवेदकको नागरिकता प्रमाणपत्रको प्रतिलिपि",
      "२) फिल्डबुक उत्तार",
      "३) घरभए सम्पत्ति कर बुझाएको प्रमाणपत्रको प्रतिलिपि",
      "३) मोहियानी हकको प्रमाणपत्र",
      "४) बाली बुझाएको वा धरौटी रसिद"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/84ZJ2MgFlN0L74CBiqgPp7PYV2l3GwTdTTrspyFP.pdf"
  },
  {
    id: "38",
    name: " मूल्याड्ढन गरी पाऊँ",
    description:
     [ 
      "१. निवेदकको नागरिकता प्रतिलिपी",
      "२. जग्गा धनी प्रमाणपूर्जाको प्रतिलिपी",
      "३. जग्गाको मालपोत तिरेको रसिद",
      "४. सम्पत्ति कर तिरेको प्रमाणपत्रको प्रतिलिपि",
      "५. मालपोत बुझाएको रसिद"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/Ql9UTPTwNj1dHMezvrTzPbgksm0pxONYO2zpGQXB.pdf"
  },

  {
    id: "39",
    name: "तीन पुस्ते खोली सिफारिस",
    description:
     [ 
      "१. निवेदकको नागरिकता प्रतिलिपी",
      "२. जग्गा धनी प्रमाणपूर्जाकोप्रतिलिपी",
      "३. घर भए सम्पत्ति कर तिरेको प्रमाणपत्रको प्रतिलिपि",
     " ४. जग्गा भए आ.ब.को मालपोत बुझाएको रसिद",
      "५. २ प्रति फोटा"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/FtfLq8XyqoPU3YDXMs0ZzAJscKLXnqOP8ybHEtfJ.pdf"
  },

  {
    id: "40",
    name: "नाता सिफारिस",
    description:
     [ 
      "१. निवेदकको नागरिकता प्रतिलिपी",
      "२. छोरा / छोरीको नागरिकताको प्रतिलिपि",
     " ३. सम्पत्ति कर तिरेको प्रमाणपत्रको प्रतिलिपि",
      "४. हालसालै खिचेको फोटो २,२ प्रति "
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/F804fCeRx1ouKxZUJRlRK1dUTD9yTHKAJqj3yWtR.pdf"
  },
  {
    id: "41",
    name: "पुरानो घरमा विद्युत जडान सिफारिस",
    description:
     [ 
      "१. निवेदकको नागरिकता प्रतिलिपी",
      "२. जग्गा धनी प्रमाणपूर्जाको प्रतिलिपि",
      "३. सम्पत्ति कर तिरेको प्रमाणपत्रको प्रतिलिपि",
      "४. नापी नक्साको प्रतिलिपि"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/1HKdIYWxqAx8JTvoUPVUiedMHmka6Pg9RHbzSjRC.pdf"
  },
  {
    id: "42",
    name: "किस्ता रकम भुक्तानी",
    description:
     [ 
      "१. किस्ता रकम भुक्तानी",
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/sRmkiovWSLXT9uJfxTajqZUsYa2sE2b5s38oTKtZ.pdf"
  },
  {
    id: "43",
    name: "नापजाँच गराई अन्तिम किस्ता रकम भुक्तानी",
    description:
     [ 
      "१. नापजाँच गराई अन्तिम किस्ता रकम भुक्तानी",
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/AAd27qwHJWrGY2eUQZK6ueHNmQ0frzB8uTKaOfkI.pdf"
  },
  {
    id: "44",
    name: "नापजाँच गराई अन्तिम किस्ता रकम भुक्तानी",
    description:
     [ 
      "१. नापजाँच गराई अन्तिम किस्ता रकम भुक्तानी",
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/AAd27qwHJWrGY2eUQZK6ueHNmQ0frzB8uTKaOfkI.pdf"
  },

  {
    id: "45",
    name: "मञ्जुरीनामा",
    description:
     [ 
      "१. दुबैको नागरिकताको प्रतिलिप",
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/2UEY5Ri3RHdoxpaxWIBwfSe5LKv7SrihM2RLocuD.pdf"
  },
  {
    id: "46",
    name: "घरधनी र घरबहालमा बस्ने ब्यक्ति बीच भएको घर बहाल सम्वन्धी सम्झौता",
    description:
     [ 
      "१. दुबैको नागरिकता प्रतिलिपी",
      "२. घर भए सम्पत्ति कर तिरेको प्रमाणपत्रको प्रतिलिपि",
      "३. जग्गा भए जग्गाधनी प्रमाणपूर्जाको प्रतिलिपि",
      "४. सम्झौता पत्र"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/TkDJoO8a3LYrdCUd3FOWBSpu8sNFjG0b8dHM4jpA.pdf"
  },
  {
    id: "47",
    name: "घरधनी र घरबहालमा बस्ने ब्यक्ति बीच भएको घर बहाल सम्वन्धी सम्झौता",
    description:
     [ 
      "१. दुबैको नागरिकता प्रतिलिपी",
      "२. घर भए सम्पत्ति कर तिरेको प्रमाणपत्रको प्रतिलिपि",
      "३. जग्गा भए जग्गाधनी प्रमाणपूर्जाको प्रतिलिपि",
      "४. सम्झौता पत्र"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/TkDJoO8a3LYrdCUd3FOWBSpu8sNFjG0b8dHM4jpA.pdf"
  },

  {
    id: "48",
    name: "मिलापत्र कागज",
    description:
     [ 
      "१) मिलापत्र गर्ने दुवै पक्षको संयुक्त निवेदन",
      "२) सम्बन्धित व्यक्तिहरुको नागरिकता प्रमाणपत्रको प्रतिलिपि",
      "३) विषयसँग सम्बन्धित अन्य कागजातहरु"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/aw4lZku7oix96DoEzFf5SmlbS12jA2Y18B61JdKo.pdf"
  },

  {
    id: "49",
    name: "कोठा खोली पाऊँ",
    description:
     [ 
      "१. निवेदकको नागरिकता प्रतिलिपी",
      "२. ३५ दिने सूचनाको पत्रिका",
      "३. सम्पत्ति कर तिरेको प्रमाणपत्रको प्रतिलिपि",
      "४. बहाल कर बुझाएको कागज"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/4sMHH76lKkx6vIOTeSrRNblY33ICvOhDswhtDEu2.pdf"
  },

  {
    id: "52",
    name: "नापी नक्सामा बाटो नभएको फिल्डमा बाटो भएको सिफारिस",
    description:
     [ 
     " १. निवेदकको नागरिकता प्रतिलिपी",
      "२. जग्गाधनी प्रमाणपूर्जाको प्रतिलिपि",
      "३. मालपोत रसिद",
      "४. नापी नक्सा"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/BWSNiSGEDPC2Nbns55arwsQ4BVUNGgEjd51sbdAa.pdf"
  },
  {
    id: "53",
    name: "धारा नामसारी सिफारिस",
    description:
     [ 
     " १. निवेदकको नागरिकता प्रतिलिपी",
     " २. जग्गाधनी प्रमाणपूर्जाको प्रतिलिपि",
      "३. नक्सा पास प्रमाणपत्र",
      "४. सम्पत्ति कर बुझाएको प्रमाणपत्र",
      "५. धाराको कागज"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/KX3GyeNKpd56DQylHK2aS0Q0ak8sltRb6xoIGrRb.pdf"
  },
  {
    id: "54",
    name: "विद्युत मिटर नामसारी सिफारिस",
    description:
     [ 
      "१. निवेदकको नागरिकता प्रतिलिपी",
      "२. जग्गाधनी प्रमाणपूर्जाको प्रतिलिपि",
      "३. नक्सा पास प्रमाणपत्र",
      "४. सम्पत्ति कर बुझाएको प्रमाणपत्र",
      "५. विद्युत महशुलको कागज"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"#"
  },

  {
    id: "55",
    name: "फोटो टाँसको लागि तीन पुस्ते खोली सिफारिस",
    description:
     [ 
      "१. निवेदकको नागरिकता प्रतिलिपी",
     " २. जग्गाधनी प्रमाणपूर्जाको प्रतिलिपि",
      "३. सम्पत्ति कर बुझाएको प्रमाणपत्र",
      "४. तीन पुस्ते खोलेको कागजात",
      "५. दुई प्रति फाटो"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/M920tKhOHZcgOxl63Moa9gB3DfN4JnJeeuF27ixK.pdf"
  },

  {
    id: "56",
    name: "फोटो टाँसको लागि तीन पुस्ते खोली सिफारिस",
    description:
     [ 
      "१. निवेदकको नागरिकता प्रतिलिपी",
     " २. जग्गाधनी प्रमाणपूर्जाको प्रतिलिपि",
      "३. सम्पत्ति कर बुझाएको प्रमाणपत्र",
      "४. तीन पुस्ते खोलेको कागजात",
      "५. दुई प्रति फाटो"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/M920tKhOHZcgOxl63Moa9gB3DfN4JnJeeuF27ixK.pdf"
  },

  {
    id: "57",
    name: "स्थायी बसोबास सिफारिस ",
    description:
     [ 
      "१. निवेदकको नागरिकता प्रतिलिपी",
      "२. घरजग्गा भए सम्पत्ति कर बुझाएको प्रमाणपत्र",
      "३. दुई प्रति फाटो"
    ],
    code: "#1abc9c",
    icon: images.factory,
    url:"https://lmcityward3.com/storage/reports/d34o1aj5o4NLIJTabduk0glsAg4mUJwLIyelNmrA.pdf"
  },
];

export default items;