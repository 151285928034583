import { Box, Link, Text, VStack } from '@chakra-ui/react'

const list = [
  {
    id: 1,
    name:'३ नं. वडा कार्यालय वेबसाइट ',
    url: 'https://lmcityward3.com',
    value: 32,
    color: 'yellow',
  },
  {
    id: 2,
    name:"ललितपुर महानगरपालिका वेबसाइट ",
    url: 'https://lalitpurmun.gov.np/en',
    value: 26,
    color: 'green',
  },
  {
    id: 3,
    name: 'नागरिक वडा ३ Android App ',
    url:"https://play.google.com/store/apps/details?id=com.naagarik.lmcityward3",
    value: 6,
    color: 'cadet',
  },
  {
    id: 4,
    name: 'वडा ३ Android App ',
    url:"https://play.google.com/store/apps/details?id=com.lmcityward3.mob",
    value: 6,
    color: 'cadet',
  },
]

function Data() {
  return (
    <VStack as="ul" spacing={0} listStyleType="none">
      {list.map(item => (
        <Box
          key={item.id}
          as="li"
          w="full"
          py={3}
          px={5}
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          borderBottomWidth={1}
          borderColor="brand.light"
        >
          <Link href={item.url} isExternal>
            <Text color="brand.dark">{item.name}</Text>
          </Link>
          {/* <Text color={`brand.${item.color}`} fontWeight="bold">
            {item.value}
          </Text> */}
        </Box>
      ))}
    </VStack>
  )
}

export default Data
