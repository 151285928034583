
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { Logo } from './Logo'
import {BrowserRouter as Router, Link} from 'react-router-dom';
import { OAuthButtonGroup } from './OAuthButtonGroup'
import { PasswordField } from './PasswordField'
import { useState, } from 'react'
import API from "../../helpers/api"
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
import DialogModal from 'components/DialogModal'


const SignUp = () =>{
  const history = useHistory();
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [cookies, setCookie] = useCookies(['access_token']);
  const [isModalOpen, setModelOpen] = useState(false);
  const [isLoading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState("");

  const SigningApi=async()=>{

    setModelOpen(true)
    setLoading(true)
    const response = await fetch(API.REGISTER, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({name:name, email: email, password: password, password_confirmation:confirmPassword})
    });
    const content = await response.json();
    setLoading(false)
    if(content.status==="Success"){
      setCookie('access_token', content.data.access_token, { path: '/' });
      setModelOpen(false)
      history.push("/dashboard")
    }else{
      setErrorMsg(content.data.message)
    }
  }
  
  return( <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
    <Stack spacing="8">
      <Stack spacing="6">
        <Logo />
        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
          <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
            Log in to your account
          </Heading>
          <HStack spacing="1" justify="center">
            <Text color="muted">Do you have an account?</Text>
            <Link to={'/login'}>
              <Button  colorScheme='blue' variant="link">
                Log in
              </Button>
            </Link>
          </HStack>
        </Stack>
      </Stack>
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
        borderRadius={{ base: 'none', sm: 'xl' }}
      >
        <Stack spacing="6">
          <Stack spacing="5">
          <FormControl>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input id="name" type="text" text = {name} onChange={(e)=> setName(e.target.value)}/>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input id="email" type="email" text = {email} onChange={(e)=> setEmail(e.target.value)}/>
            </FormControl>
            <PasswordField label={'Password'} onChange={(e)=>setPassword(e.target.value)}/>
            <PasswordField label={'Confirm Password'} onChange={(e)=>setConfirmPassword(e.target.value)}/>
          </Stack>
          <HStack justify="space-between">
            <Checkbox defaultChecked>Remember me</Checkbox>
            <Button variant="link" colorScheme={'blue'} size="sm">
              Forgot password?
            </Button>
          </HStack>
          <Stack spacing="6">
            <Button onClick={()=> SigningApi()} >Sign up</Button>
          </Stack>
        </Stack>
      </Box>
    </Stack>
    <DialogModal isOpen={isModalOpen} isLoading={isLoading} onClose={()=>setModelOpen(false)} desc={errorMsg}/>
  </Container>
)
}

export default SignUp;











