
const Nepali={
    citizenship:"नागरिकता",
    liscense:"सवारी चालक अनुमती पत्र (लाईसेन्स)",
    passport:"राहदानी",
    pan:"स्थायी लेखा नं",
    land:"जग्गा धनी प्रमाणपुर्जा",
    billbook:"सवारी दर्ता प्रमाणपत्र",
    election:"मतदाता परिचयपत्र",
    national:"राष्ट्रिय परिचयपत्र",
    electricity:"नेपाल बिधुत प्राधिकारण (बिजुलि) ",
    tap:"काठमाण्डु उपत्यका खानेपानी लिमिटेड(धारा) ",
    insurance:"जीवन बिमा",
    covid:"कोविड-१९ कार्ड",
    property:"सम्पती मूल्यांकन प्रमाणपत्र ",
    business:"व्यवसाय प्रमाणपत्र",
    marriage: "बिवाह दर्ता",
    naksa:"नक्सा पास",
    others:"अन्य",
    certificate:"शैक्षिक प्रमाणपत्र"
}

export default Nepali;