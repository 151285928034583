
const  BASE_URL = process.env.REACT_APP_BASE_URL;

const API ={
    BASE_URL : BASE_URL,
    LOGIN: `${BASE_URL}/api/auth/login`,
    REGISTER: `${BASE_URL}/api/auth/signup`,
    FORGET_PASSWORD: `${BASE_URL}/api/forget/password`,
    USER:`${BASE_URL}/api/user`,
    POST_PROFILE:`${BASE_URL}/api/profile`,
    UPLOAD_FILE :`${BASE_URL}/api/documents`,
    UPLOAD_PROFILE :`${BASE_URL}/api/profile/picture`,
    GET_DOCUMENTS:`${BASE_URL}/api/documents`,
    DELETE_DOCUMENT:`${BASE_URL}/api/document`,
    GET_PROFILE:`${BASE_URL}/api/profile`,

}
export default API;

