import {
    Heading,
    Avatar,
    Box,
    Center,
    Text,
    Stack,
    Button,
    Link,
    Badge,
    useColorModeValue,
  } from '@chakra-ui/react';
  
  export default function BadaPatra({imgUrl, title, desc, id, url}) {
    console.log("desc ", desc)

    return (
      <Center py={6}>
        <a href={url} target="_blank">
        <Box
          w={'full'}
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'2xl'}
          rounded={'lg'}
          p={6}
          textAlign={'center'}>
          {/* <Avatar
            size={'xl'}
            src={
              imgUrl
            }
            alt={'Avatar Alt'}
            mb={4}
            pos={'relative'}
            _after={{
              content: '""',
              w: 4,
              h: 4,
              bg: 'green.300',
              border: '2px solid white',
              rounded: 'full',
              pos: 'absolute',
              bottom: 0,
              right: 3,
            }}
          /> */}
          <Heading fontSize={'2xl'} fontFamily={'body'} pb={5}>
           {title}
          </Heading>
          {/* <Text fontWeight={600} color={'gray.500'} mb={4}>
            @lindsey_jam3s
          </Text> */}

          {
            desc.map(txt=> <Text 
              textAlign={'left'}
              // color={useColorModeValue('gray.700', 'gray.400')}
              px={3} py={1}>
                  {txt}
            </Text>)
          }
          
  
          
        </Box>
        </a>
      </Center>
    );
  }