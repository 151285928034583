import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {
  Button,
  Text,
  SimpleGrid,
  HStack,
  Image

} from '@chakra-ui/react'
import API from 'helpers/api';
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
import ImagePreview from './ImagePreview';

function MyDropzone({type, imageUrl, onUploadSuccess , OnImageDeleteClick}) {

  console.log("image ulr", imageUrl)

  const history = useHistory();
  const [cookies, setCookie] = useCookies(['access_token']);
  
  const onDrop =  useCallback(async(acceptedFiles) => {

    const formData = new FormData()
    formData.append("type", type)
    formData.append("filename[]", acceptedFiles[0])

    const token = cookies.access_token

    if(!token){
      history.push("/login")
      return;
    }

    // return;
    const response = await fetch(API.UPLOAD_FILE, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
        
      },
      body: formData
    });

    
    const content = await response.json();
    console.log(" new upload ", content)
    if(content.status==='Success'){
      onUploadSuccess(type, content.data)
     
    }


  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div>
    <div {...getRootProps()}>
      
      <input {...getInputProps()} />
      <HStack spacing='24px'>
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <p>Drag 'n' drop some files here, <br/> or click to select files</p>
        }
      </HStack>
     
    </div>

  <ImagePreview imageUrl={imageUrl} OnImageDeleteClick={(image)=>OnImageDeleteClick(image)}/>
  </div>
  )
}

export default MyDropzone