import {   
  Button,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Center,
  ModalOverlay, Spinner} from '@chakra-ui/react'


export default function DialogModal({isOpen, onClose, desc, isLoading}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} >
    <ModalOverlay />
    
    {!isLoading && <ModalContent>
      <ModalHeader>Error !!! </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text>{desc}</Text>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onClose}>Close</Button>
      </ModalFooter>
    </ModalContent>}
    {isLoading && <ModalContent> <ModalHeader>Please wait ... </ModalHeader><ModalBody><Spinner /></ModalBody></ModalContent>}

  </Modal>
  )
}
