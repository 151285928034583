import {
    Heading,
    Avatar,
    Box,
    Center,
    Text,
    Stack,
    Button,
    Link,
    Badge,
    useColorModeValue,
    Container,
    SimpleGrid,
    Divider
  } from '@chakra-ui/react';
import BadaPatra from './Badapatra';
import { Footer } from './Footer';
import badapatra from './../helpers/badapatra'

import React,{useState, useEffect} from 'react'
import { Header } from './Header';
  
  export default function Home() {

    const [badapartras, setBadapatras] = useState([])
    useEffect(()=>{
      setBadapatras(badapatra)
    },[])

    return (
      // <Container display={{ base: 'block', md: 'flex' }} maxW="container.xl" bg={"#156d20"}>
      <Stack bg={""}>
        <Header/>

          <SimpleGrid columns={[1, null, 3]} spacingX='40px' spacingY='20px' px={10}>
            {
              badapartras.map(badapatra=> <BadaPatra 
                id={badapatra.id}
                title={badapatra.name}
                desc = {badapatra.description}
                imgUrl={badapatra.icon}
                url = {badapatra.url? badapatra.url:"#"}
                />)
            }
          
          </SimpleGrid>

        <Footer/>
      </Stack>
      // </Container>
    );
  }