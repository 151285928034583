import ads from "./../assets/images/ads.png"
import agreement from  "./../assets/images/agreement.png"
const images = { 
  ads: ads,
  agreement: agreement,
  bankruptcy: require("./../assets/images/bankruptcy.png"),
  birth: require("./../assets/images/birth.png"),
  boy: require("./../assets/images/boy.png"),
  business_card: require("./../assets/images/business-card.png"),
  card: require("./../assets/images/card.png"),
  clean_water: require("./../assets/images/clean-water.png"),
  court: require("./../assets/images/court.png"),
  cow: require("./../assets/images/cow.png"),
  crisis: require("./../assets/images/crisis.png"),
  divorce: require("./../assets/images/divorce.png"),
  document: require("./../assets/images/document.png"),
  drawing: require("./../assets/images/drawing.png"),
  electronic_music: require("./../assets/images/electronic-music.png"),
  english: require("./../assets/images/english.png"),
  factory: require("./../assets/images/factory.png"),
  funeral: require("./../assets/images/funeral.png"),
  ghar_patal: require("./../assets/images/ghar_patal.png"),
  graduate_cap: require("./../assets/images/graduate-cap.png"),
  graduate: require("./../assets/images/graduate.png"),
  house: require("./../assets/images/house.png"),
  light_bulb: require("./../assets/images/light-bulb.png"),
  logo: require("./../assets/images/logo.png"),
  marriage: require("./../assets/images/marriage.png"),
  medicine: require("./../assets/images/medicine.png"),
  money: require("./../assets/images/money.png"),
  money_1: require("./../assets/images/money_1.png"),
  organisation: require("./../assets/images/organisation.png"),
  passport: require("./../assets/images/passport.png"),
  project: require("./../assets/images/project.png"),
  refugee: require("./../assets/images/refugee.png"),
  rent: require("./../assets/images/rent.png"),
  road: require("./../assets/images/road.png"),
  rohobar: require("./../assets/images/rohobar.png"),
  same_sex_marriage: require("./../assets/images/same-sex-marriage.png"),
  scholarship: require("./../assets/images/scholarship.png"),
  school: require("./../assets/images/school.png"),
  settlement: require("./../assets/images/settlement.png"),
  smart_farm: require("./../assets/images/smart-farm.png"),
  strike: require("./../assets/images/strike.png"),
  teacher: require("./../assets/images/teacher.png"),
  team: require("./../assets/images/team.png"),
  town: require("./../assets/images/town.png"),
  unmarried: require("./../assets/images/unmarried.png"),
  wheelchair: require("./../assets/images/wheelchair.png"),
  cover: require("./../assets/cover.jpg"),
  tim:require("./../assets/tim_cook.jpg")
} 
  export default images;