import { ButtonGroup, Container, IconButton, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
// import { Logo } from './Logo'

export const Footer = () => (
  <Container as="footer" role="contentinfo" py={{ base: '12', md: '16' }}>
    <Stack spacing={{ base: '4', md: '5' }}>
      <Text fontSize="sm" color="subtle">
        &copy; {new Date().getFullYear()} सर्वाधिकार - ललितपुर महानगरपालिका | ३ नं. वडा कार्यालय | फोन नं: ०१-५४२८१८८ / ९८५१०९२९४८

      </Text>
    </Stack>
  </Container>
)