import { useState, useRef, useEffect } from 'react'
import {
  Avatar,
  AvatarBadge,
  Badge,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import API from 'helpers/api'
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";


function Profile() {
  const [userProfile, setUserProfile] = useState(null)
  const [fullName, setFullName] = useState("")
  const [phone, setPhone] = useState("")
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['access_token']);

  const { isOpen, onOpen, onClose } = useDisclosure()
  const profileImage = useRef(null)



  useEffect(async()=>{
    const token = cookies.access_token
    if(!token){
      history.push("/login")
      return;
    }

    const response = await fetch(API.GET_PROFILE, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${token}`
      }
    });
    const content = await response.json();
    console.log("content ", content)
    if(content.status==="Success"){
      if(content && content.data && content.data.user.profile && content.data.user.profile.picture)
         setFullName(content.data.user.profile.full_name)
          setPhone(content.data.user.profile.mobile)
          setUserProfile(`${process.env.REACT_APP_BASE_URL}${content.data.user.profile.picture.filename}`)
  return;
    }

  },[])

  const openChooseImage = () => {
    profileImage.current.click()
  }

  const changeProfileImage = async(event) => {
    const ALLOWED_TYPES = ['image/png', 'image/jpeg', 'image/jpg']
    const selected = event.target.files[0]

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      let reader = new FileReader()
      reader.onloadend = () => setUserProfile(reader.result)
      reader.readAsDataURL(selected)

      const formData = new FormData()
      formData.append("filename", selected)
      formData.append("type",'PROFILE');

      const token = cookies.access_token

      if(!token){
        history.push("/login")
        return;
      }

    const response = await fetch(API.UPLOAD_PROFILE, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
        
      },
      body: formData
    });
    const content = await response.json();
    if(content.status==="Success"){
      if(content && content.data && content.data.user.profile && content.data.user.profile.picture)
      // console.log(content)
      // ${process.env.REACT_APP_BASE_URL}/${content.data.img}
      setUserProfile(`${process.env.REACT_APP_BASE_URL}${content.data.user.profile.picture.filename}`)
  return;
    }
    
    }

    onOpen()
  }

  return (
    <VStack spacing={3} py={5} borderBottomWidth={1} borderColor="brand.light">
      <Avatar
        size="2xl"
        name={fullName}
        cursor="pointer"
        onClick={openChooseImage}
        src={userProfile ? userProfile : '/img/tim-cook.png'}
      >
        <AvatarBadge bg="brand.blue" boxSize="1em">
          <svg width="0.4em" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
            />
          </svg>
        </AvatarBadge>
      </Avatar>
      <input
        hidden
        type="file"
        ref={profileImage}
        onChange={changeProfileImage}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Something went wrong</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>File not supported!</Text>
            <HStack mt={1}>
              <Text color="brand.cadet" fontSize="sm">
                Supported types:
              </Text>
              <Badge colorScheme="green">PNG</Badge>
              <Badge colorScheme="green">JPG</Badge>
              <Badge colorScheme="green">JPEG</Badge>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <VStack spacing={1}>
        <Heading as="h3" fontSize="xl" color="brand.dark">
          {fullName}
        </Heading>
        <Text color="brand.gray" fontSize="sm">
         {phone}
        </Text>
      </VStack>
    </VStack>
  )
}

export default Profile
