
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { Logo } from './Logo'
import { OAuthButtonGroup } from './OAuthButtonGroup'
import { PasswordField } from './PasswordField'
import { useState } from 'react'
import API from "./../../helpers/api"
import {BrowserRouter as Router, Link} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
import DialogModal from 'components/DialogModal'
import React,{useEffect} from 'react'
import { emailValidator } from 'helpers/formvalidator/emailValidator'

const Login = () =>{
  
  const [cookies, setCookie] = useCookies(['access_token']);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const history = useHistory();
  const [isModalOpen, setModelOpen] = useState(false);
  const [isLoading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(()=>{
    const token = cookies.access_token;
    if(token){
      history.push("/dashboard")
    }
  },[])

  const LoginApi = async()=>{

    setModelOpen(true)
    setLoading(true)

    if(emailValidator(email).length>0){
      setModelOpen(true)
      setLoading(false)
      setErrorMsg(emailValidator(email))
      return;
    }
    
    const response = await fetch(API.LOGIN, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: email, password: password})
    });
    const content = await response.json();
    console.log("content ", content)
    if(content.status=="Success"){
      setModelOpen(false)
      setCookie('access_token', content.data.access_token, { path: '/' });
      history.push("/dashboard")
    }else{
      setErrorMsg(content.data?.message)
      
    }
    setLoading(false)
    console.log(content)
  }
  
  return( 
  <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
    <Stack spacing="8">
      <Stack spacing="6">
        <Logo />
        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
          <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
            Log in to your account
          </Heading>
          <HStack spacing="1" justify="center">
            <Text color="muted">Don't have an account?</Text>
            <Link to="/register">
              <Button  colorScheme='blue' variant="link">
                Sign up
              </Button>
            </Link>
          </HStack>
        </Stack>
      </Stack>
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
        borderRadius={{ base: 'none', sm: 'xl' }}
      >
        <Stack spacing="6">
          <Stack spacing="5">
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input id="email" type="email" text = {email} onChange={(e)=> setEmail(e.target.value)}/>
            </FormControl>
            <PasswordField onChange={(e)=>setPassword(e.target.value)}/>
          </Stack>
          <HStack justify="space-between">
            <Checkbox defaultChecked>Remember me</Checkbox>
            <Button variant="link" colorScheme={'blue'} size="sm">
              Forgot password?
            </Button>
          </HStack>
          <Stack spacing="6">
            <Button onClick={()=>LoginApi()} >Sign in</Button>
          </Stack>
        </Stack>
      </Box>
    </Stack>
    <DialogModal isOpen={isModalOpen} isLoading={isLoading} onClose={()=>setModelOpen(false)} desc={errorMsg}/>
  </Container>
)
}

export default Login;











