import SimpleBar from 'simplebar-react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from 'helpers'
import Cover from 'components/Cover'
import Main from 'components/Main'
import Login from 'components/Auth/Login'
import SignUp from 'components/Auth/SignUp'
import { CookiesProvider } from "react-cookie";
import BadaPatra from 'components/Badapatra'
import Home from 'components/Home'

export default function App() {
  return (
    <CookiesProvider>
      <SimpleBar style={{ maxHeight: '100vh' }}>
        <ChakraProvider theme={theme}>
          <Router>
            <Switch>
              <Route path="/dashboard" exact>
                <Cover />
                <Main />
              </Route>
              <Route path="/" exact>
                <Home/>
              </Route>
              <Route path="/login" exact>
                <Login/>
              </Route>
              <Route path="/register" exact>
                <SignUp/>
              </Route>
            </Switch>
          </Router>
        </ChakraProvider>
      </SimpleBar>
    </CookiesProvider>
  )
}
