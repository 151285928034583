import { useRef, useState } from 'react'
import {
 SimpleGrid,
 Image,
 Button,
 Box
} from '@chakra-ui/react'

export default function ImagePreview({imageUrl, OnImageDeleteClick}) {
  return (
    <SimpleGrid columns={3} spacing={10}>
      {
          imageUrl.map(image=>(
            <Box>
              <Button
        // position="absolute"
        top={10}
        right={4}
        variant="ghost"
        onClick={()=>OnImageDeleteClick(image)}
      >
        <Image src ='/img/close.png' 
          boxSize='16px'
          objectFit='cover' />
      </Button>

          <Image  
          onClick={()=> window.open(`${process.env.REACT_APP_BASE_URL}${image.filename}` , "_blank") }
          boxSize='100px'
          objectFit='cover'
          fallbackSrc='https://via.placeholder.com/150'
          src={`${process.env.REACT_APP_BASE_URL}${image.filename}`} alt='Dan Abramov' />

        </Box>
          ))
        }
      </SimpleGrid>
  )
}
