import { ButtonGroup, Container, IconButton, Stack, Text,  VStack, HStack, Flex, Spacer, Button, Box } from '@chakra-ui/react'
import * as React from 'react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
import  Logo  from './../assets/images/logo.png'
import NepaliDate from 'english-to-nepali-date'
import { useHistory } from "react-router-dom";
export const Header = () => {
  
  const history = useHistory()
  return(

    <Flex  py={10} px={10} bg={'#156d20'} direction={['column','row']} alignItems={'center'}>
      <Box p={2}>
        <img src={Logo} alt={"Ward 3"}  />
      </Box>
      <Box ml={4} p={2}>
        <Text color={'#fff'}>ललितपुर महानगरपालिका</Text>
        <Text color={'#fff'}>३ नं. वडा कार्यालय</Text>
        <Text color={'#fff'}>पुल्चोक, ललितपुर</Text>
        <Text color={'#fff'}>०१-५४२८१८८ / ९८५१०९२९४८</Text>
      </Box>
          
      <Spacer />
      <Box p='4'   >
        <Text color={'#fff'} > मिति: {  new NepaliDate(new Date().getFullYear(), new Date().getMonth()+1 , new Date().getDate()).format("")}</Text>
        <Button  variant={'link'} color={'#fff'} onClick={()=>{history.push("/login")}}>Login</Button>
      </Box>
  </Flex>

)}